import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './videoRender.actionTypes';
import { addWatchLog } from '../watch-logs/watch-logs.helper';
import { viewerRenderRecordSessionFailureAction } from './videoRender.actions';

const getViewerSession = (state) => state.viewerReducer.viewerSession;

function* recordViewerLog() {
	console.log('rec')
    try {
        const viewerSession = yield select(getViewerSession);
		console.log(viewerSession);
        if (viewerSession) {
			console.log("got viewerSession");
            yield call(addWatchLog, {
                videoGroupId: viewerSession?.info?.videoGroupId,
                videoId: viewerSession?.info?.videoId,
				sessionTime: viewerSession?.info?.sessionTime,
				progressStatus: viewerSession,
                record: viewerSession?.record
            });
        }
    } catch (e) {
        yield put(viewerRenderRecordSessionFailureAction(e));
    }
}

export default function* usersSagas() {
    yield takeLatest(actionTypes.VIEWER_RENDER_RECORD_SESSION, recordViewerLog);
}
