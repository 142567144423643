import * as actionTypes from './generate-codes.actionTypes';

export const fetchPraxiCodesAction = (params) => ({
    payload: { params },
    type: actionTypes.FETCH_PRAXI_CODES
});

export const fetchPraxiCodesSuccessAction = (praxiCodesResponse) => ({
    payload: { praxiCodesResponse },
    type: actionTypes.FETCH_PRAXI_CODES_SUCCESS
});

export const fetchPraxiCodesFailureAction = (errorResponse) => ({
    payload: { errorResponse },
    type: actionTypes.FETCH_PRAXI_CODES_FAILURE
});

export const addPraxiCodeAction = (codeDetails) => ({
    payload: { codeDetails },
    type: actionTypes.ADD_PRAXI_CODES
});

export const addPraxiCodeSuccessAction = () => ({
    type: actionTypes.ADD_PRAXI_CODES_SUCCESS
});

export const addPraxiCodeFailureAction = (errorResponse) => ({
    payload: { errorResponse },
    type: actionTypes.ADD_PRAXI_CODES_FAILURE
});

export const fetchPraxiCodeDetailsAction = (praxiCodesId) => ({
    payload: { praxiCodesId },
    type: actionTypes.FETCH_PRAXI_CODES_DETAILS
});

export const fetchPraxiCodeDetailsSuccessAction = (praxiCodesDetailsResponse) => ({
    payload: { praxiCodesDetailsResponse },
    type: actionTypes.FETCH_PRAXI_CODES_DETAILS_SUCCESS
});

export const fetchPraxiCodeDetailsFailureAction = (errorResponse) => ({
    payload: { errorResponse },
    type: actionTypes.FETCH_PRAXI_CODES_DETAILS_FAILURE
});
