import { fetchApi } from '../../utils/fetchHelper';
import endpoints from './../../configs/endpoints';

export const addPraxiCode = (groupDetails) =>
    fetchApi({
        method: 'POST',
        url: endpoints.praxiCodes,
        data: groupDetails
    }, true);

export const fetchPraxiCodes = (params) =>
    fetchApi({
        method: 'GET',
        url: endpoints.praxiCodes,
        params: params
    }, true);

export const fetchPraxiCodeDetails = (id) =>
    fetchApi({
        method: 'GET',
        url: endpoints.praxiCodeById(id)
    }, true);

export const updatePraxiCode = (id, codeDetails) =>
    fetchApi({
        method: 'PATCH',
        url: endpoints.praxiCodeById(id),
        data: codeDetails
    }, true);

export const deletePraxiCode = (id) =>
    fetchApi({
        method: 'DELETE',
        url: endpoints.praxiCodeById(id)
    }, true);
