// assets
import { IconVideoPlus, IconFolders, IconListSearch, IconVideo, IconFolderPlus } from '@tabler/icons';
import { VideoLibrary } from '@material-ui/icons';

// constant
const icons = {
    IconVideo,
    IconVideoPlus,
    IconFolders,
    VideoLibrary,
    IconListSearch,
    IconFolderPlus
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const codeGeneratorMenu = {
    id: 'code_menu',
    title: 'Praxi Tools',
    type: 'group',
    children: [
        {
            id: 'code_list_menu',
            title: 'Codes',
            type: 'collapse',
            url: '/praxi-codes',
            icon: icons['IconVideo'],
            breadcrumbs: false,
            children: [
                {
                    id: 'code_all',
                    title: 'All Codes',
                    type: 'item',
                    url: '/praxi-codes',
                    icon: icons['VideoLibrary'],
                    breadcrumbs: false
                },
                {
                    id: 'add_code_all',
                    title: 'Generate Code',
                    type: 'item',
                    url: '/praxi-codes/add',
                    icon: icons['IconVideoPlus'],
                    breadcrumbs: false
                }
            ]
        }
    ]
};
