import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './generate-codes.actionTypes';
import * as praxiCodeActions from './generate-codes.actions';

import { addPraxiCode, fetchPraxiCodeDetails, fetchPraxiCodes } from './generate-codes.helper';

import toast from 'react-hot-toast';

function* fetchPraxiCodesSaga(action) {
    try {
        const { params } = action.payload;
        const response = yield call(fetchPraxiCodes, params);
        yield put(praxiCodeActions.fetchPraxiCodesSuccessAction(response));
    } catch (e) {
        toast.error(e.message || 'Something went wrong!');
        yield put(praxiCodeActions.fetchPraxiCodesFailureAction(e));
    }
}

function* addPraxiCodeSaga(action) {
    try {
        const { history, ...codeDetails } = action.payload.codeDetails;
        const response = yield call(addPraxiCode, codeDetails);
        yield put(praxiCodeActions.addPraxiCodeSuccessAction(response));
        toast.success('Code added successfully!');
        yield call(history.push, '/praxi-codes');
    } catch (e) {
        toast.error(e.message || 'Something went wrong!');
        yield put(praxiCodeActions.addPraxiCodeFailureAction(e));
    }
}

function* fetchPraxiCodeDetailsSaga(action) {
    try {
        const { praxiCodesId } = action.payload;
        const response = yield call(fetchPraxiCodeDetails, praxiCodesId);
        yield put(praxiCodeActions.fetchPraxiCodeDetailsSuccessAction(response));
    } catch (e) {
        toast.error(e.message || 'Something went wrong!');
        yield put(praxiCodeActions.fetchPraxiCodeDetailsFailureAction(e));
    }
}

export default function* praxiCodesSagas() {
    yield takeLatest(actionTypes.ADD_PRAXI_CODES, addPraxiCodeSaga);
    yield takeLatest(actionTypes.FETCH_PRAXI_CODES, fetchPraxiCodesSaga);
    yield takeLatest(actionTypes.FETCH_PRAXI_CODES_DETAILS, fetchPraxiCodeDetailsSaga);
}
