const endpoints = {
    register: `/auth/register`,
    registerInstructors: `/auth/register-instructors`,
    login: `/auth/login`,
    verifyEmail: `/auth/verify-email`,
    forgotPassword: `/auth/forgot-password`,
    resetPassword: `/auth/reset-password`,
    users: `/users`,
    userById: (id) => `/users/${id}`,
    instructors: `/instructors`,
    instructorById: (id) => `/instructors/${id}`,
    students: `/students`,
    studentById: (id) => `/students/${id}`,
    videoGroups: `/video-groups`,
    praxiCodes: `/praxi-codes`,
    activatePraxiCode: `/praxi-codes/activate`,
    praxiCodeById: (id) => `/praxi-codes/${id}`,
    videoGroupById: (id) => `/video-groups/${id}`,
    videos: `/videos`,
    videosById: (id) => `/videos/${id}`,
    videoMarkers: `/video-markers`,
    watchLogs: `/watch-logs`,
    groupedWatchLogs: `/watch-logs/grouped`,
    watchLogById: (id) => `/watch-logs/${id}`,
    errorLogs: `/error-logs`,
    errorLogById: (id) => `/error-logs/${id}`,
    upload_file: `videos/upload_file`,
    quizAnswers: `/quiz-answers`
};

export default endpoints;
