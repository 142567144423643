import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from '../views/authentication/session/auth.reducer';
import usersReducer from '../views/users/users.reducer';
import viewerReducer from '../views/video-viewer/videoRender.reducer';
import videoGroupsReducer from '../views/video-group/video-groups.reducer';
import praxiCodesReducer from '../views/praxi-codes/generate-codes.reducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const createRootReducer = () => combineReducers({
    authReducer,
    usersReducer,
    viewerReducer,
    videoGroupsReducer,
    praxiCodesReducer,
    customization: customizationReducer
});

export default createRootReducer;
