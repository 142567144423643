export const FETCH_PRAXI_CODES = '@auth/FETCH_PRAXI_CODES';
export const FETCH_PRAXI_CODES_SUCCESS = '@auth/FETCH_PRAXI_CODES_SUCCESS';
export const FETCH_PRAXI_CODES_FAILURE = '@auth/FETCH_PRAXI_CODES_FAILURE';

export const ADD_PRAXI_CODES = '@auth/ADD_PRAXI_CODES';
export const ADD_PRAXI_CODES_SUCCESS = '@auth/ADD_PRAXI_CODES_SUCCESS';
export const ADD_PRAXI_CODES_FAILURE = '@auth/ADD_PRAXI_CODES_FAILURE';

export const FETCH_PRAXI_CODES_DETAILS = '@auth/FETCH_PRAXI_CODES_DETAILS';
export const FETCH_PRAXI_CODES_DETAILS_SUCCESS = '@auth/FETCH_PRAXI_CODES_DETAILS_SUCCESS';
export const FETCH_PRAXI_CODES_DETAILS_FAILURE = '@auth/FETCH_PRAXI_CODES_DETAILS_FAILURE';
