
// MAKE SURE THAT YOU SET 'config.env' TO 'prod' WHEN YOU BUILD!!!

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    dashboardPath: '/dashboard',
    defaultPath: '/session/login',
    // fontFamily: `'Roboto', sans-serif`,
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    env: 'dev',
    dev: {
        baseURL: 'https://praxi.guans.cs.kent.edu:8181/v1/',
        URL: 'https://praxi.guans.cs.kent.edu:8181/'
    },
    prod: {
        baseURL: 'https://praxi.guans.cs.kent.edu/v1/',
        URL: 'https://praxi.guans.cs.kent.edu/'
    }
};

export default config;
