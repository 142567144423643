import React from 'react';

// import logoDark from './../../src/assets/images/logo-dark.svg';
// import logo from './../../src/assets/images/logo.svg';
// import logo from './../../src/assets/images/xri_logo_horizontal.png';
import logo from './../../src/assets/images/xri_logo_center.png';

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    return (
        <img src={logo} alt="Berry" width="100" />
    );
};

export default Logo;
