export const FETCH_VIDEO_GROUPS = '@auth/FETCH_VIDEO_GROUPS';
export const FETCH_VIDEO_GROUPS_SUCCESS = '@auth/FETCH_VIDEO_GROUPS_SUCCESS';
export const FETCH_VIDEO_GROUPS_FAILURE = '@auth/FETCH_VIDEO_GROUPS_FAILURE';

export const ADD_VIDEO_GROUPS = '@auth/ADD_VIDEO_GROUPS';
export const ADD_VIDEO_GROUPS_SUCCESS = '@auth/ADD_VIDEO_GROUPS_SUCCESS';
export const ADD_VIDEO_GROUPS_FAILURE = '@auth/ADD_VIDEO_GROUPS_FAILURE';

export const FETCH_VIDEO_GROUPS_DETAILS = '@auth/FETCH_VIDEO_GROUPS_DETAILS';
export const FETCH_VIDEO_GROUPS_DETAILS_SUCCESS = '@auth/FETCH_VIDEO_GROUPS_DETAILS_SUCCESS';
export const FETCH_VIDEO_GROUPS_DETAILS_FAILURE = '@auth/FETCH_VIDEO_GROUPS_DETAILS_FAILURE';
