import * as actionTypes from './generate-codes.actionTypes';

export const initialState = {
    praxiCodes: null,
    praxiCodeDetails: null,
    isLoading: false,
    error: null
};

//-----------------------|| AUTH REDUCER ||-----------------------//

const praxiCodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRAXI_CODES:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.FETCH_PRAXI_CODES_SUCCESS:
            return {
                ...state,
                praxiCodes: action.payload.praxiCodesResponse,
                isLoading: false
            };
        case actionTypes.FETCH_PRAXI_CODES_FAILURE:
            return {
                ...state,
                error: action.payload.errorResponse,
                isLoading: false
            };
        case actionTypes.ADD_PRAXI_CODES:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.ADD_PRAXI_CODES_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.ADD_PRAXI_CODES_FAILURE:
            return {
                ...state,
                error: action.payload.errorResponse,
                isLoading: false
            };
        case actionTypes.FETCH_PRAXI_CODES_DETAILS:
            return {
                ...state,
                praxiCodeDetails: null,
                isLoading: true
            };
        case actionTypes.FETCH_PRAXI_CODES_DETAILS_SUCCESS:
            return {
                ...state,
                praxiCodeDetails: action.payload.praxiCodesDetailsResponse,
                isLoading: false
            };
        case actionTypes.FETCH_PRAXI_CODES_DETAILS_FAILURE:
            return {
                ...state,
                praxiCodeDetails: null,
                error: action.payload.errorResponse,
                isLoading: false
            };
        default:
            return state;
    }
};

export default praxiCodesReducer;
