import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@material-ui/core';

// project imports
import AnimateButton from './../../../../ui-component/extended/AnimateButton';
import Box from '@mui/material/Box';

// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.warning.light,
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '90px',
            right: '-150px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    tagLine: {
        color: theme.palette.grey[900],
        opacity: 0.6
    },
    input: {
        ...theme.typography.customInput
    },
    button: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.warning.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    }
}));

//-----------------------|| PROFILE MENU - UPGRADE PLAN CARD ||-----------------------//

const ApplyCodeCard = ({ handleApplyCodeSubmit, handleCodeChange, praxiCode }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h4'>Assign video group</Typography>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Typography variant='subtitle2' className={classes.tagLine}>*/}
                    {/*        Enter provided Code*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    <Box sx={{ ml: 2, mt: 2 }}>
                        <form>
                            {/*<Grid item xs={12} sm={4}>*/}
                            <FormControl fullWidth className={classes.input}>
                                <InputLabel htmlFor='private-code'>Enter provided Code</InputLabel>
                                <OutlinedInput
                                    id='private-code'
                                    type='text'
                                    value={praxiCode}
                                    name='private-code'
                                    onBlur={handleCodeChange}
                                    onChange={handleCodeChange}
                                    label='Enter provided Code'
                                    inputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                />
                            </FormControl>
                            {/*</Grid>*/}
                        </form>
                    </Box>
                    <Grid item>
                        <Stack direction='row'>
                            <AnimateButton>
                                <Button variant='contained' className={classes.button} onClick={handleApplyCodeSubmit}>
                                    Apply Code
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ApplyCodeCard;
